<template>
  <div class="ledger-export">
    <el-popover trigger="click" width="180">
      <template #reference>
        <el-button class="elv-base-button-export" link>
          <SvgIcon name="reports-export" :width="14" :height="14" />
          {{ t('button.export') }}
        </el-button>
      </template>
      <div class="ledger-export-select">
        <div class="ledger-export-select-item" @click="onClickExport('ELVEN')">Export CSV</div>
        <div class="ledger-export-select-item" @click="onClickExport('XERO')">Export for Xero</div>
      </div>
    </el-popover>
    <ElvMessageBox
      ref="exportMessageBoxRef"
      :confirmButtonText="t('button.confirm')"
      :cancelButtonText="t('button.cancel')"
      :title="t('title.exportData')"
      :loading="exportLoading"
      @onCancelEvent="onCancelExportData"
      @onConfirmEvent="onConfirmExportData"
    >
      <template #content>
        <span class="elv-confirm-export-info">{{ t('message.exportDataInfo') }}</span></template
      >
    </ElvMessageBox>
    <ElvMessageBox
      ref="noConfigMessageBoxRef"
      :confirmButtonText="t('title.mapping')"
      :cancelButtonText="t('button.cancel')"
      :title="t('title.accountMapping')"
      :loading="exportLoading"
      @onCancelEvent="onCancelNoConfig"
      @onConfirmEvent="onConfirmNoConfig"
    >
      <template #content>
        <span class="elv-confirm-export-info">{{ t('title.accountMappingDesc') }}</span></template
      >
    </ElvMessageBox>
  </div>
</template>
<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import LedgerApi from '@/api/LedgerApi'

type ParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  noPermission: {
    type: Boolean,
    required: true
  },
  params: {
    type: Object as () => ParamsType,
    required: true
  }
})

const { t } = useI18n()
const route = useRoute()
const router = useRouter()

const exportLoading = ref(false)
const exportParams: any = ref({})
const currentType = ref('')
const exportMessageBoxRef = useTemplateRef('exportMessageBoxRef')
const noConfigMessageBoxRef = useTemplateRef('noConfigMessageBoxRef')

const entityId = computed(() => {
  return route.params?.entityId as string
})

const onExport = (data: any) => {
  if (props.noPermission) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  if (isEmpty(data)) {
    exportParams.value = {}
  } else {
    exportParams.value = data
  }
  exportMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmExportData = async () => {
  try {
    exportLoading.value = true
    const data: any = isEmpty(exportParams.value) ? props.params : exportParams.value
    if (currentType.value === 'XERO') {
      data.extra.type = 'XERO'
    }
    await ProjectApi.exportExcelData(entityId.value, data)
    ElMessage.success(t('message.success'))
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    exportLoading.value = false
    exportMessageBoxRef.value?.onCheckMessageBoxDialog()
  }
}

const onCancelExportData = () => {
  exportMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onCancelNoConfig = () => {
  noConfigMessageBoxRef.value?.onCheckMessageBoxDialog()
}

const onConfirmNoConfig = () => {
  router.push({
    name: 'entity-ledger-chart-of-account',
    params: {
      entityId: entityId.value
    }
  })
}

async function onClickExport(type: string) {
  const relationships: any[] = []
  function recurse(items: any) {
    items.forEach((item: any) => {
      if (item.thirdpartyChartOfAccount) {
        relationships.push({
          chartOfAccountId: item.chartOfAccountId,
          thirdpartyChartOfAccountCode: item.thirdpartyChartOfAccount.code
        })
      }
      if (item.list && item.list.length > 0) {
        recurse(item.list)
      }
    })
  }

  if (type === 'XERO') {
    const { data } = await LedgerApi.getChartOfAccountList(entityId.value, { thirdpartyPlatform: 'XERO' })
    recurse(data)
    if (relationships.length === 0) {
      noConfigMessageBoxRef.value?.onCheckMessageBoxDialog()
      return
    }
  }
  currentType.value = type
  onExport({})
}
</script>
<style lang="scss" scoped>
.elv-base-button-export {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  line-height: 15px;
  cursor: pointer;
  margin-left: 8px;

  svg {
    fill: #838d95;
    margin-right: 8px;
  }

  &:hover {
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.ledger-export-select {
  margin: -12px 0;
  color: #0e0f11;
  font-family: 'Plus Jakarta Sans';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;

  &-item {
    line-height: 40px;
    cursor: pointer;
  }
}
</style>
