<template>
  <div class="elv-ledger-header-main-right">
    <el-button v-if="false" link class="elv-ledger-header-main-right__edit" @click="onBatchEdit">
      <SvgIcon name="reports-batch-edit" width="16" height="16" />
      {{ t('button.edit') }}
    </el-button>

    <template v-if="props.tableType === 'chartOfAccount'">
      <el-button
        v-if="entityStore.entityDetail?.startingDate"
        class="elv-ledger-header-main-right__export"
        link
        @click="onOpenOpeningBalanceDialog"
      >
        <SvgIcon name="balance-add" width="14" height="14" />
        {{ t('common.openingBalance') }}
      </el-button>

      <el-button class="elv-ledger-header-main-right__export" link @click="onOpenChartOfAccountMappingDialog">
        <SvgIcon name="Calculation" width="14" height="14" />
        {{ t('title.accountMapping') }}
      </el-button>

      <el-button class="elv-ledger-header-main-right__export" link @click="onOpenTreasuryAccountMappingDialog">
        <SvgIcon name="Calculation" width="14" height="14" />
        {{ t('title.treasuryAccountMapping') }}
      </el-button>
    </template>

    <elv-button
      v-if="
        props.tableType === 'chartOfAccount' || props.tableType === 'journalType' || props.tableType === 'auxiliaryCode'
      "
      class="elv-ledger-header-main-right__add"
      type="primary"
      round
      plain
      width="95"
      height="32"
      @click="onAddNew"
      ><SvgIcon name="source-add" width="16" height="16" fill="#ffffff" />{{ t('button.addNew') }}</elv-button
    >

    <LedgerExport
      v-if="props.tableType === 'generalLedger'"
      :params="exportParams"
      :noPermission="
        ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.generalLedger?.sync
      "
    />

    <elv-export-button
      v-if="props.tableType === 'accountBalance'"
      :params="exportParams"
      class="elv-ledger-header-main-right__export"
      :noPermission="
        ['MEMBER', ''].includes(currentEntityPermission?.role) && !currentEntityPermission?.generalLedger?.sync
      "
    />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { ElMessage } from 'element-plus'
import { useEntityStore } from '@/stores/modules/entity'
import ElvExportButton from '@/components/Base/ElvExportButton.vue'
import LedgerExport from './LedgerExport.vue'

type ParamsType = {
  type: string
  extra: Object
}

const props = defineProps({
  tableType: {
    type: String,
    default: 'chartOfAccount'
  },
  exportParams: {
    type: Object as () => ParamsType,
    required: true
  }
})

const { t } = useI18n()
const entityStore = useEntityStore()
const emit = defineEmits([
  'onAddNew',
  'onCheckBatchTransactions',
  'onOpenOpeningBalanceDialog',
  'onOpenTreasuryAccountMappingDialog',
  'onOpenChartOfAccountMappingDialog'
])

const currentEntityPermission = computed(() => {
  return entityStore.entityPermission()
})

const onBatchEdit = () => {
  if (
    ['MEMBER', ''].includes(currentEntityPermission.value?.role) &&
    !currentEntityPermission.value?.transaction?.update
  ) {
    ElMessage.warning(t('message.noPermission'))
    return
  }
  console.log(123)
}

const onOpenTreasuryAccountMappingDialog = () => {
  emit('onOpenTreasuryAccountMappingDialog')
}

const onOpenChartOfAccountMappingDialog = () => {
  emit('onOpenChartOfAccountMappingDialog')
}

const onOpenOpeningBalanceDialog = () => {
  emit('onOpenOpeningBalanceDialog')
}

const onAddNew = () => {
  emit('onAddNew')
}
</script>

<style lang="scss" scoped>
.elv-ledger-header-main-right {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}

.elv-ledger-header-main-right__edit {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  height: 32px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;

  svg {
    fill: #838d95;
    margin-right: 8px;
  }

  &:not(.is-disabled):hover,
  &:not(.is-disabled):focus,
  &:not(.is-disabled):active {
    background-color: #fff;
    color: #1343bf;

    svg {
      fill: #1343bf;
    }
  }
}

.elv-ledger-header-main-right__add.elv-button.el-button--primary {
  float: right;
  font-weight: 500;
  font-size: 12px;
}

.elv-ledger-header-main-right__export {
  float: right;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
  border-radius: 2px;
  font-family: 'Plus Jakarta Sans';
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #1e2024;
  cursor: pointer;
  margin-left: 16px;

  svg {
    fill: #838d95;
    margin-right: 6px;
  }

  &:hover {
    color: #1343bf;
    background-color: #fff;

    svg {
      fill: #1343bf;
    }
  }
}
</style>
